import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import slugify from "slugify"; // For creating slugs, if needed
import "./blogdetails.css"
import FaqPage from "../FaqPage/QuestionPage";

const BlogDetail = () => {
  const { slug } = useParams(); // Get blog slug from the URL
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBlogDetail = async () => {
      try {
        const response = await axios.get(`https://www.thebrainmoney.com/v1/getBlogs`);
        const blogs = response.data.data;
        
        // Find the blog matching the slug
        const foundBlog = blogs.find((b) => 
          slugify(b.title, { lower: true, strict: true }) === slug
        );
        
        if (foundBlog) {
          setBlog(foundBlog);
        } else {
          setError("Blog not found.");
        }
        
        setLoading(false);
      } catch (error) {
        console.error("Error fetching the blog details:", error);
        setError("Failed to load blog details.");
        setLoading(false);
      }
    };

    fetchBlogDetail();
  }, [slug]);
    function createMarkup() {
        return {
            __html: blog.content    };
    }
    if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
      <div className="blog-detail-container">
          <div className="breadcrumbs"><span><span><a href="/">Home</a></span> » <span><a
              href="/blog/">Blog</a></span> » <span className="breadcrumb_last blogd1" aria-current="page">{blog.title}</span></span>
          </div>
          <div className="title">
              <h1>{blog.title}</h1>
              <div className="post-info">
                  <div>
                      Written by:
                      <a href="/" className="author-name">
                          <div className="author-avatar">
                              <img width="145" height="150" src="/images/user.webp" className="attachment-thumbnail size-thumbnail entered lazyloaded" alt="" decoding="async" data-lazy-src="/images/user.webp" data-ll-status="loaded"/>
                          </div>
                          <span className="h6">{blog.author}</span>
                      </a>
                  </div>
                  <div className="data-info">
                      <div className="data-date">
                          Sep 24
                      </div>
                      <div className="data-read-time">
                          5 min read
                      </div>
                      <a href="/blog/category/youtube/" className="data-category"> YouTube </a>
                  </div>
                  <div>
                      Publish date: <b>
                      {new Date(blog.created_at).toLocaleDateString()} </b>
                  </div>
                  <div>
                      Last update: <b>
                      {new Date(blog.updated_at).toLocaleDateString()} </b>
                  </div>
              </div>
          </div>

          {/*<div className="imageblogdetails">*/}
          {/*    <img src={blog.feature_images} alt={blog.title}/>*/}
          {/*</div>*/}
          <p className="author">By {blog.author}</p>
          <p>{new Date(blog.created_at).toLocaleDateString()}</p>
          <div className="content container single-page-container">
              <div className="content-wrap js-content-wrapper" dangerouslySetInnerHTML={createMarkup()}/>
              <p></p> {/* If content has HTML, use dangerouslySetInnerHTML */}
          </div>

          <div className="tags">
              {blog.tags.split(", ").map((tag, index) => (
                  <span key={index} className="tag">
            {tag}
          </span>
              ))}
          </div>
          <FaqPage/>
      </div>

  );
};

export default BlogDetail;
